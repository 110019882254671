import { useEffect, useState } from 'react'
import AgenciesIcon from 'components/sidebar/icons/agencies-icon'
import ReportingIcon from 'components/sidebar/icons/reporting-icon'
import UrbanXIcon from 'components/sidebar/icons/urbanx-icon'
import SettingsIcon from 'components/sidebar/icons/settings-icon'
import { Link, useLocation } from 'react-router-dom'
import { StaffAction } from 'types/staff'
import './sidebar.scss'
import ArrowUpIcon from './icons/arrowup-icon'
import { Breakpoints, useWindowSize } from '@urbanx/agx-ui-components'
import AutoPilotIcon from './icons/autopilot'
import AutoPilotIconWithText from './icons/autoPilotIconWithText'
interface Props {
  isMenuCollapsed: boolean
  setIsMenuCollapsed: (value: boolean) => void
}

const MENU_ITEMS = {
  AGENCIES: 'Agencies',
  NETWORK: 'Network',
  PARTNER: 'Partner',
  EXECUTIVE: 'Executive',
  EDIT_PROFILE: 'Edit profile',
  STAFF_MEMBERS: 'Staff members',
  AGENCY_DETAILS: 'Agency details',
  SETTINGS: 'Settings',
}
const REPORTING_SUBMENU = [
  MENU_ITEMS.NETWORK,
  MENU_ITEMS.PARTNER,
  MENU_ITEMS.EXECUTIVE,
]
const URBANX_SUBMENU = [
  MENU_ITEMS.EDIT_PROFILE,
  MENU_ITEMS.STAFF_MEMBERS,
  MENU_ITEMS.AGENCY_DETAILS,
]

const SideBar = (props: Props) => {
  const { isMenuCollapsed, setIsMenuCollapsed } = props
  const { state, pathname } = useLocation()
  const [activeLink, setActiveLink] = useState('')
  const [menuStates, setMenuStates] = useState<Record<string, boolean>>({
    urbanx: false,
    reporting: false,
  })
  const screenSize = useWindowSize()
  const isMobile = screenSize === Breakpoints.Mobile
  const isTabletPortrait = screenSize === Breakpoints.Tablet_Portrait

  const toggleState = (key: string) => {
    setMenuStates((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  const updateActiveLink = (link: string) => {
    setActiveLink(link)
    if (isMobile || isTabletPortrait) {
      setIsMenuCollapsed(true)
    }
  }
  useEffect(() => {
    if (pathname) {
      switch (pathname) {
        case '/': {
          setActiveLink(MENU_ITEMS.AGENCIES)
          break
        }
        case '/network': {
          setActiveLink(MENU_ITEMS.NETWORK)
          setMenuStates({ urbanx: false, reporting: true })
          break
        }
        case '/partners': {
          setActiveLink(MENU_ITEMS.PARTNER)
          setMenuStates({ urbanx: false, reporting: true })
          break
        }
        case '/executive-report': {
          setActiveLink(MENU_ITEMS.EXECUTIVE)
          setMenuStates({ urbanx: false, reporting: true })
          break
        }
        case '/staff': {
          if (state && state.action === 'view')
            setActiveLink(MENU_ITEMS.EDIT_PROFILE)

          setActiveLink(MENU_ITEMS.STAFF_MEMBERS)
          setMenuStates({ urbanx: true, reporting: false })
          break
        }
        case '/staff-license': {
          setActiveLink(MENU_ITEMS.AGENCY_DETAILS)
          setMenuStates({ urbanx: true, reporting: false })
          break
        }
      }
    }
  }, [])

  return (
    <div
      className={`sidebar ${
        isMenuCollapsed ? 'collapsed' : 'sidebar-expanded'
      }`}
      onMouseOver={() => {
        setIsMenuCollapsed(false)
      }}
      onMouseOut={() => {
        setIsMenuCollapsed(true)
      }}
    >
      <nav>
        <ul className="menuShort">
          <li>
            <div className="menu-item">
              <AutoPilotIcon />
            </div>
          </li>

          <li>
            <div className="menu-item">
              <AgenciesIcon
                opacity={activeLink === MENU_ITEMS.AGENCIES ? 1 : 0.6}
              />
            </div>
          </li>
          <li>
            <div className="menu-item">
              <ReportingIcon
                opacity={REPORTING_SUBMENU.includes(activeLink) ? 1 : 0.6}
              />
            </div>
          </li>
          <li>
            <div className="menu-item">
              <UrbanXIcon
                opacity={URBANX_SUBMENU.includes(activeLink) ? 1 : 0.6}
              />
            </div>
          </li>
          <li>
            <div className="menu-item">
              <SettingsIcon
                opacity={activeLink === MENU_ITEMS.SETTINGS ? 1 : 0.6}
              />
            </div>
          </li>
        </ul>
      </nav>
      <nav>
        <ul className={`menu ${isMenuCollapsed ? 'collapsed' : ''}`}>
          <li>
            <div className="autoPilotIcon">
              <AutoPilotIconWithText />
            </div>
          </li>
          <li
            className={`menu-item-container ${
              activeLink === 'Agencies' ? 'menu-item-active' : ''
            }`}
            onClick={() => updateActiveLink(MENU_ITEMS.AGENCIES)}
          >
            <Link className="linkStyle" to={'/'}>
              <div className="menu-item">
                <AgenciesIcon
                  opacity={activeLink === MENU_ITEMS.AGENCIES ? 1 : 0.6}
                />
                <span
                  className={`menu-item-text ${
                    activeLink === MENU_ITEMS.AGENCIES
                      ? 'submenu-active-text'
                      : ''
                  }`}
                >
                  Agencies
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`has-submenu ${menuStates['reporting'] ? 'open' : ''}`}
            data-submenu-count={REPORTING_SUBMENU.length}
          >
            <div
              className={`submenuContainer ${
                REPORTING_SUBMENU.includes(activeLink) ? 'active' : ''
              }`}
              onClick={() => {
                !isMenuCollapsed && toggleState('reporting')
              }}
            >
              <div className="menu-item">
                <ReportingIcon
                  opacity={REPORTING_SUBMENU.includes(activeLink) ? 1 : 0.6}
                />
                <span
                  className={`menu-item-text ${
                    REPORTING_SUBMENU.includes(activeLink)
                      ? 'submenu-active-text'
                      : ''
                  }`}
                >
                  Reporting
                </span>
              </div>
              <ArrowUpIcon
                extraClasses="ic"
                opacity={REPORTING_SUBMENU.includes(activeLink) ? 1 : 0.6}
              />
            </div>
            <ul className="submenu">
              <li onClick={() => updateActiveLink(MENU_ITEMS.NETWORK)}>
                <Link className="linkStyle" to={'/network'}>
                  <div
                    className={`submenu-item ${
                      activeLink === MENU_ITEMS.NETWORK
                        ? 'submenu-item-active'
                        : ''
                    }`}
                  >
                    <span
                      className={`menu-item-text ${
                        activeLink === MENU_ITEMS.NETWORK
                          ? 'submenu-active-text'
                          : ''
                      }`}
                    >
                      Network
                    </span>
                  </div>
                </Link>
              </li>
              <li onClick={() => updateActiveLink(MENU_ITEMS.PARTNER)}>
                <Link className="linkStyle" to={'/partners'}>
                  <div
                    className={`submenu-item ${
                      activeLink === MENU_ITEMS.PARTNER
                        ? 'submenu-item-active'
                        : ''
                    }`}
                  >
                    <span
                      className={`menu-item-text ${
                        activeLink === MENU_ITEMS.PARTNER
                          ? 'submenu-active-text'
                          : ''
                      }`}
                    >
                      Partner
                    </span>
                  </div>
                </Link>
              </li>
              <li onClick={() => updateActiveLink(MENU_ITEMS.EXECUTIVE)}>
                <Link className="linkStyle" to={'/executive-report'}>
                  <div
                    className={`submenu-item ${
                      activeLink === MENU_ITEMS.EXECUTIVE
                        ? 'submenu-item-active'
                        : ''
                    }`}
                  >
                    <span
                      className={`menu-item-text ${
                        activeLink === MENU_ITEMS.EXECUTIVE
                          ? 'submenu-active-text'
                          : ''
                      }`}
                    >
                      Executive
                    </span>
                  </div>
                </Link>
              </li>
            </ul>
          </li>
          <li
            className={`has-submenu ${menuStates['urbanx'] ? 'open' : ''}`}
            data-submenu-count={URBANX_SUBMENU.length}
          >
            <div
              className={`submenuContainer ${
                URBANX_SUBMENU.includes(activeLink) ? 'active' : ''
              }`}
              onClick={() => {
                !isMenuCollapsed && toggleState('urbanx')
              }}
            >
              <div className="menu-item">
                <UrbanXIcon
                  opacity={URBANX_SUBMENU.includes(activeLink) ? 1 : 0.6}
                />
                <span
                  className={`menu-item-text ${
                    URBANX_SUBMENU.includes(activeLink)
                      ? 'submenu-active-text'
                      : ''
                  }`}
                >
                  UrbanX
                </span>
              </div>

              <ArrowUpIcon
                extraClasses="ic"
                opacity={URBANX_SUBMENU.includes(activeLink) ? 1 : 0.6}
              />
            </div>
            <ul className="submenu">
              <li onClick={() => updateActiveLink(MENU_ITEMS.EDIT_PROFILE)}>
                <Link
                  className="linkStyle"
                  to={'/staff'}
                  state={{ action: StaffAction.SELF_EDIT }}
                >
                  <div
                    className={`submenu-item ${
                      activeLink === MENU_ITEMS.EDIT_PROFILE
                        ? 'submenu-item-active'
                        : ''
                    }`}
                  >
                    <span
                      className={`menu-item-text ${
                        activeLink === MENU_ITEMS.EDIT_PROFILE
                          ? 'submenu-active-text'
                          : ''
                      }`}
                    >
                      Edit profile
                    </span>
                  </div>
                </Link>
              </li>
              <li onClick={() => updateActiveLink(MENU_ITEMS.STAFF_MEMBERS)}>
                <Link
                  className="linkStyle"
                  to={'/staff'}
                  state={{ action: StaffAction.VIEW }}
                >
                  <div
                    className={`submenu-item ${
                      activeLink === MENU_ITEMS.STAFF_MEMBERS
                        ? 'submenu-item-active'
                        : ''
                    }`}
                  >
                    <span
                      className={`menu-item-text ${
                        activeLink === MENU_ITEMS.STAFF_MEMBERS
                          ? 'submenu-active-text'
                          : ''
                      }`}
                    >
                      Staff members
                    </span>
                  </div>
                </Link>
              </li>
              <li onClick={() => updateActiveLink(MENU_ITEMS.AGENCY_DETAILS)}>
                <Link className="linkStyle" to={'/staff-license'}>
                  <div
                    className={`submenu-item ${
                      activeLink === MENU_ITEMS.AGENCY_DETAILS
                        ? 'submenu-item-active'
                        : ''
                    }`}
                  >
                    <span
                      className={`menu-item-text ${
                        activeLink === MENU_ITEMS.AGENCY_DETAILS
                          ? 'submenu-active-text'
                          : ''
                      }`}
                    >
                      Agency details
                    </span>
                  </div>
                </Link>
              </li>
            </ul>
          </li>
          <li
            className={`menu-item-container ${
              activeLink === MENU_ITEMS.SETTINGS ? 'menu-item-active' : ''
            }`}
          >
            <div className="menu-item">
              <SettingsIcon
                opacity={activeLink === MENU_ITEMS.SETTINGS ? 1 : 0.6}
              />
              <span
                className={`menu-item-text ${
                  activeLink === MENU_ITEMS.SETTINGS
                    ? 'submenu-active-text'
                    : ''
                }`}
              >
                Settings
              </span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  )
}
export default SideBar
