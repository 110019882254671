import { useContext, useEffect, useState } from 'react'
import { AgencyContext } from 'contexts'
import { PackageItemType } from 'types/agency'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxTextInput,
  AgxSelect,
  AgxHeader,
  AgxCaption,
  AgxCurrency,
  Images,
  MarketingItemType,
  AgxToast,
} from '@urbanx/agx-ui-components'
import { v4 as uuidv4 } from 'uuid'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SetMarketingItemTemplates } from 'services'

import './editItems.css'
import DeleteConfirmation from 'components/delete-confirmation/DeleteConfirmation'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import { AgxToastState } from 'types/commonTypes'
import { MarketingItemTemplate } from 'types/marketing'

interface EditItemsProps {
  onBack: () => void
  existingItems: MarketingItemTemplate[]
  agencyId: string
}
type PackageItemKeys = keyof MarketingItemTemplate

const itemTypeOptions: { value: string; label: PackageItemType }[] = [
  { value: MarketingItemType.Price.toString(), label: PackageItemType.PRICE },
  {
    value: MarketingItemType.Complementary.toString(),
    label: PackageItemType.COMPLIMENTARY,
  },
  {
    value: MarketingItemType.AgentToFill.toString(),
    label: PackageItemType.AGENT_TO_FILL,
  },
]
const EditItems = (props: EditItemsProps) => {
  const queryClient = useQueryClient()
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [forceCheck, setForceCheck] = useState(false)
  const [selectedAgency, _] = useContext(AgencyContext)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<
    MarketingItemTemplate | undefined
  >(undefined)
  const { existingItems, onBack, agencyId } = props
  const [items, setItems] = useState<MarketingItemTemplate[]>([])
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const { mutate: updateItems, data: updateResponse } = useMutation(
    SetMarketingItemTemplates,
    {
      onSuccess: () => {
        setIsFormDirty(false)
        queryClient.invalidateQueries({
          queryKey: [`packageItem-${agencyId}`, agencyId],
        })
        updateToastState({
          color: 'success',
          message: 'Marketing package(s) updated successfully',
          open: true,
        })
      },
      onError: () => {
        updateToastState({
          color: 'error',
          message: 'Error updating Marketing package(s)',
          open: true,
        })
      },
    }
  )

  const [errorList, setErrorList] = useState<string[]>([])

  const [, getAuthToken] = useAzureAuth()

  useEffect(() => {
    if (existingItems) {
      setItems([...existingItems])
    }
  }, [existingItems])

  useEffect(() => {
    if (updateResponse === 204) {
      queryClient.invalidateQueries({
        queryKey: [`packageItem-${selectedAgency?.id}`, selectedAgency?.id],
      })
      onBack()
    }
  }, [updateResponse])

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (items && items?.length > 0) {
      const updatedItems = [...items]
      const [reorderedItem] = updatedItems.splice(result.source.index, 1)
      updatedItems.splice(result.destination.index, 0, reorderedItem)

      setItems(updatedItems)
    }
  }

  const onSaveHandler = () => {
    const errors = validateForm()
    if (errors.length === 0) {
      setErrorList([])
      updateItems({
        agencyId: selectedAgency?.id || '',
        marketingItemTemplates: sanatizeItems(),
        getAuthToken,
      })
    } else {
      setErrorList(errors)
    }
  }

  const itemObjectUpdateHandler = (
    item: MarketingItemTemplate,
    value: string,
    prop: PackageItemKeys
  ) => {
    let defaultValue = ''
    const convertedValue = value === null || value === undefined ? '' : value
    if (prop === 'pricePerUnit') {
      defaultValue =
        item[prop] === null || item[prop] === undefined
          ? ''
          : parseFloat(`${item[prop]}`).toFixed(2).toString()
    } else {
      defaultValue =
        item[prop] === null || item[prop] === undefined
          ? ''
          : (item[prop] as string).toString()
    }

    if (defaultValue !== convertedValue) {
      setIsFormDirty(true)
    }

    setItems(
      items.map((i) => {
        if (i.id === item.id) {
          return { ...i, [prop]: value }
        }

        return i
      })
    )
  }

  const sanatizeItems = () => {
    const sanatizedItems = items.map((item, index) => {
      // The AgxSelect onValueChanged() function returns a string, so we need to convert it to a number
      item.itemType = Number(item.itemType)
      if (item.itemType !== 0) {
        return { ...item, agencyId: agencyId, pricePerUnit: undefined, order: index + 1 }
      }

      return { ...item, agencyId: agencyId, order: index + 1 }
    })

    return sanatizedItems
  }

  const validateForm = (): string[] => {
    const errors: string[] = []

    const itemPrices: Record<string, number | undefined> = {}

    items.forEach((item) => {
      // Check for empty product names
      if (item.productName.trim() === '') {
        errors.push('Product name cannot be empty')
      }

      // Get the trimmed product name and price
      const productName = item.productName.trim()
      const price = item.pricePerUnit

      // Check for duplicate product names with the same price
      if (productName in itemPrices) {
        const storedPrice = itemPrices[productName]
        if (storedPrice === price) {
          errors.push(`Duplicate entry for product name ${productName}`)
        }
      } else {
        itemPrices[productName] = price
      }
    })

    return errors
  }

  const onDeleteItemHandler = (item: MarketingItemTemplate) => {
    setSelectedItemToDelete(item)
    setShowDeleteConfirmation(true)
  }

  const onConfirmDelete = () => {
    if (selectedItemToDelete) {
      setIsFormDirty(true)
      setItems(items.filter((i) => i.id !== selectedItemToDelete.id))
      setShowDeleteConfirmation(false)
    }
  }

  const renderItems = (item: MarketingItemTemplate, index: number) => {
    return (
      <Draggable key={item.id} draggableId={item.id || ''} index={index}>
        {(provided) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <AgxRow
              centered
              spaceBetween
              veryLargeGap
              extraClasses="borderBottomContainer"
            >
              <AgxRow centered veryLargeGap fill>
                <div className="thPackageNameContainer__marketing">
                  <Images.DragDropIcon />
                </div>
                <AgxTextInput
                  id="txtProductName"
                  label=""
                  noOptionalLabel
                  stretch
                  // Autofocus the top newly added item name field
                  autoFocus={items[0].productName === ''}
                  defaultValue={item.productName}
                  onInputValueChange={({ value }: { value: string }) =>
                    itemObjectUpdateHandler(item, value, 'productName')
                  }
                />
                <AgxTextInput
                  id="txtSupplier"
                  label=""
                  noOptionalLabel
                  stretch
                  defaultValue={item.supplier}
                  onInputValueChange={({ value }: { value: string }) =>
                    itemObjectUpdateHandler(item, value, 'supplier')
                  }
                />

                <AgxTextInput
                  id="txtStockType"
                  label=""
                  noOptionalLabel
                  stretch
                  defaultValue={item.stockType}
                  onInputValueChange={({ value }: { value: string }) =>
                    itemObjectUpdateHandler(item, value, 'stockType')
                  }
                />

                <AgxSelect
                  id={`priceType-${item.id}`}
                  label=""
                  options={itemTypeOptions}
                  defaultValue={{
                    label: itemTypeOptions[Number(item.itemType)].label,
                    value: itemTypeOptions[Number(item.itemType)].value,
                  }}
                  hideOptionalLabel
                  onValueChanged={({ value }: { value: string }) => {
                    itemObjectUpdateHandler(item, value, 'itemType')
                  }}
                />
                <AgxCurrency
                  id={`priceAmount-${item.id}`}
                  required
                  noHeader
                  stretch
                  defaultValue={`${item.pricePerUnit}`}
                  onInputValueChange={(change) => {
                    itemObjectUpdateHandler(
                      item,
                      change.value as string,
                      'pricePerUnit'
                    )
                  }}
                  decimalPoints={2}
                  disabled={item.itemType.toString() !== '0'}
                />
              </AgxRow>
              <div className="actionContainerTD">
                <AgxButton
                  text=""
                  small
                  naked
                  leftIcon={<Images.CrossFill />}
                  onClick={() => onDeleteItemHandler(item)}
                />
              </div>
            </AgxRow>
          </li>
        )}
      </Draggable>
    )
  }

  const onBackClick = () => {
    isFormDirty ? setForceCheck(true) : onBack()
  }

  return (
    <AgxRow fill>
      <FormPrompt
        hasUnsavedChanges={isFormDirty}
        forceCheck={forceCheck}
        resetForceCheck={(value: boolean) => setForceCheck(value)}
        onBack={onBack}
      />
      <AgxToast selector="#agxToast" toastState={toastState} />
      <AgxColumn fill largeGap>
        {errorList.length > 0 && (
          <AgxColumn fill mediumGap>
            <AgxHeader size={4}>
              Some errors were found while validating the marketing items
            </AgxHeader>
            {errorList.map((error, idx) => (
              <AgxCaption key={idx}>{error}</AgxCaption>
            ))}
          </AgxColumn>
        )}
        <AgxRow veryLargeGap spaceBetween extraClasses="borderBottomContainer">
          <AgxButton
            text="Marketing Package"
            large
            naked
            onClick={() => onBackClick()}
          />
          <AgxButton
            text="Save Changes"
            medium
            primary
            onClick={() => onSaveHandler()}
          />
        </AgxRow>
        <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
          <Droppable droppableId="marketingPackages">
            {(provided) => (
              <ul
                className="marketingPackagesList"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <li>
                  <AgxRow
                    centered
                    spaceBetween
                    veryLargeGap
                    extraClasses="borderBottomContainer"
                  >
                    <AgxRow veryLargeGap spaceBetween fill centered>
                      <div className="opacity0">
                        <Images.DragDropIcon />
                      </div>
                      <AgxTextInput
                        id="txtInclusionName"
                        label="Inclusion name"
                        noOptionalLabel
                        stretch
                        extraClasses="hideWrapper"
                      />
                      <AgxTextInput
                        id="txtSupplierName"
                        label="Supplier name"
                        noOptionalLabel
                        stretch
                        extraClasses="hideWrapper"
                      />
                      <AgxTextInput
                        id="txtStockType"
                        label="Stock Type"
                        noOptionalLabel
                        stretch
                        extraClasses="hideWrapper"
                      />
                      <AgxTextInput
                        id="txtPrice"
                        label="Price"
                        noOptionalLabel
                        stretch
                        extraClasses="hideWrapper"
                      />
                      <AgxTextInput
                        id="txtEmpty"
                        label=""
                        noOptionalLabel
                        stretch
                        extraClasses="hideWrapper"
                      />
                      <div className="opacity0">
                        <AgxButton text="" small naked />
                      </div>
                    </AgxRow>
                    <div className="addItemButtonContainer">
                      <AgxButton
                        text="Add Item"
                        small
                        hollow
                        onClick={() => {
                          setIsFormDirty(true)
                          const newItem: MarketingItemTemplate = {
                            productName: '',
                            itemType: 0,
                            id: `${uuidv4()}`,
                          }
                          setItems([newItem, ...items])
                        }}
                      />
                    </div>
                  </AgxRow>
                </li>
                {items && items.map((item, index) => renderItems(item, index))}

                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </AgxColumn>
      <DeleteConfirmation
        name={selectedItemToDelete?.productName || ''}
        title="Delete marketing item?"
        lineOne="Are you sure you want to delete"
        lineTwo="This cannot be undone and will remove the item from any marketing packages that it has been added to."
        primaryActionTitle="Permanently delete item"
        secondaryActionTitle="No, cancel"
        showPopUp={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => onConfirmDelete()}
      />
    </AgxRow>
  )
}

export default EditItems
