import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import { DocumentDefaults } from 'types/documentDefaults'
import AgencyService from 'services/agenciesService'

export interface AgencyDocumentDefaults
  extends ApiRequestBody,
    DocumentDefaults {}

const UpdateAgencyDocumentDefaults = async (
  requestBody: AgencyDocumentDefaults
): Promise<number | undefined> => {
  const { getAuthToken, agencyId, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.put(
    'UpdateAgencyDocumentDefaults',
    {
      agencyId,
      documentDefaults: {
        ...requestProperties,
      },
    }
  )

  return result.status
}

export default UpdateAgencyDocumentDefaults
