import {
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useState,
} from 'react'
import PlaceHolderImage from '../placeholder-image/PlaceholderImage'
import { useNavigate } from 'react-router-dom'
import {
  AgxHeader,
  AgxRow,
  AgxColumn,
  Images,
  AgxButton,
  CampaignDetailModel,
} from '@urbanx/agx-ui-components'
import { useParams } from 'react-router-dom'
import './pageheader.scss'
import { Campaign } from 'types/campaigns'
import { Pages } from '../../constants/menu'
import { cleanTwoLineAddress } from '../../helpers/address'
import placeHolderImage from '../../assets/images/placeholder-property.png'

type PageHeaderHandle = {
  closeMenuIfOpen: () => void
}
export interface PageHeaderProps {
  agencyName: string
  agentName: string
  isDashboard?: boolean
  isStaff?: boolean
  isBlankContract?: boolean
  isError?: boolean
  agentImgUrl?: string
  agencyLogoUrl?: string
  currentPageTitle?: string
  selectedCampaign?: Campaign | CampaignDetailModel
}

const PageHeader: ForwardRefRenderFunction<
  PageHeaderHandle,
  PageHeaderProps
> = (props, ref) => {
  const {
    agencyName,
    agentName,
    currentPageTitle,
    isDashboard,
    isError,
    agentImgUrl,
    agencyLogoUrl,
    isBlankContract,
    selectedCampaign,
  } = props

  const [showMenu, setShowMenu] = useState(false)
  const imgUrl = isDashboard ? agentImgUrl : agencyLogoUrl
  const navigate = useNavigate()
  const { agencyId } = useParams()

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(
    selectedCampaign?.address
  )

  useImperativeHandle(ref, () => ({
    closeMenuIfOpen() {
      if (showMenu) setShowMenu(false)
    },
  }))

  if (isError) {
    return (
      <div className="headerContaineronError">
        <section className="titleBar">
          <div onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
            <Images.UrbanXWhite />
          </div>
          {imgUrl ? (
            <img className="titleLogo" src={imgUrl} />
          ) : (
            <PlaceHolderImage size={32} title={agentName} />
          )}
        </section>
      </div>
    )
  }

  return (
    <div>
      {!isBlankContract && (
        <section className="headerRow">
          {agencyName === 'UrbanX' ? (
            <AgxColumn extraClasses="paddingRight2">
              <Images.RealEstateLogo />
            </AgxColumn>
          ) : imgUrl ? (
            <img className="headerLogo" src={imgUrl} />
          ) : (
            <PlaceHolderImage
              size={56}
              title={isDashboard ? agentName : agencyName}
            />
          )}

          <div className="headerTitle" data-testid="headerTitle">
            <AgxHeader size={2}>
              {isDashboard ? `Hey ${agentName}` : agencyName}{' '}
              {currentPageTitle && (
                <span className="breadcrumb__header">{` / ${currentPageTitle}`}</span>
              )}
            </AgxHeader>
          </div>
        </section>
      )}
      {isBlankContract && (
        <section className="headerRow">
          <AgxColumn largeGap>
            <AgxRow>
              <AgxButton
                text="All Campaigns"
                medium
                naked
                onClick={() => navigate(`/${agencyId}/${Pages.CAMPAIGNS}`)}
              />
            </AgxRow>
            <AgxRow largeGap extraClasses="contractCard">
              <img
                className="propertyImage"
                width={180}
                src={
                  selectedCampaign?.propertyImageUrls?.large ?? placeHolderImage
                }
              />
              <AgxColumn fill>
                <AgxHeader size={2}>
                  {addressLineOne ? (
                    <>
                      {addressLineOne},<br />
                    </>
                  ) : (
                    <>
                      Address
                      <br />
                      Unidentified
                    </>
                  )}
                  {addressLineTwo ?? ''}
                </AgxHeader>
              </AgxColumn>
            </AgxRow>
          </AgxColumn>
        </section>
      )}
    </div>
  )
}

export default forwardRef(PageHeader)
