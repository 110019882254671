/* eslint-disable @typescript-eslint/no-unused-vars */
import { JSX, ReactElement, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuTitles, StaffMenu, BlankContractMenu } from 'constants/menu'
import { SideMenu } from 'types/commonTypes'
import { useParams } from 'react-router-dom'

import './contentlayout.css'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useQuery } from '@tanstack/react-query'
import { AgencyDetails } from 'types/agency'
import { GetAgencyDetails } from 'services'
import { AustralianState } from '@urbanx/agx-ui-components'
import { AgencyContext } from 'contexts'
import slugify from 'slugify'

export interface ContentLayoutProps {
  children: ReactElement | ReactElement[]
  isStaff?: boolean
  isBlankContract?: boolean
  activeMenu?: MenuTitles
  hasSideMenu?: boolean
  hasSideBar?: boolean
  sideBarContent?: () => JSX.Element
}

const ContentLayout = (props: ContentLayoutProps) => {
  const {
    hasSideMenu,
    hasSideBar,
    sideBarContent,
    activeMenu,
    isStaff,
    isBlankContract,
    children,
  } = props
  const { agencyId, campaignId } = useParams()
  const [, getAuthToken, userHasRequiredRoles] = useAzureAuth()
  const [selectedAgency] = useContext(AgencyContext)

  const {
    data: agencyDetails,
    isFetched,
    isLoading,
  } = useQuery<AgencyDetails | undefined>({
    queryKey: ['getAgencyDetails', agencyId],
    queryFn: (queryKey) => GetAgencyDetails(queryKey, getAuthToken),
    enabled: !!agencyId,
  })

  const generateLink = (pageLink: string) => {
    if (pageLink === '') return '/'

    if (agencyId) {
      if (campaignId) {
        return `/${agencyId}/campaigns/${campaignId}/${pageLink}`
      }

      return `/${agencyId}/${pageLink}`
    }
    return pageLink || '#'
  }

  if (agencyId && (!isFetched || isLoading)) return null

  const agencyStates = selectedAgency?.states ?? []

  const renderSideMenu = (menuItems: SideMenu[]) => {
    const selectedStates = agencyStates.map(
      (index) => Object.values(AustralianState)[index]
    )

    return menuItems
      .filter((menu) => userHasRequiredRoles(menu.requiredRoles))
      .filter(
        (menu) =>
          menu.includeOnlyInState.length === 0 ||
          menu.includeOnlyInState.some((state) =>
            selectedStates.includes(state)
          )
      )
      .map((menu, index) => (
        <li key={`sideMenu_${index}`} className="menuItem">
          <Link
            data-testid={`link-${slugify(menu.title)}`}
            to={generateLink(menu.pageLink ?? '')}
            className={
              activeMenu && activeMenu === menu.title
                ? 'menuLinkStyleActive'
                : 'menuLinkStyle'
            }
          >
            {menu.title}
          </Link>
        </li>
      ))
  }

  return (
    <div
      className={
        hasSideMenu || hasSideBar ? 'contentWithMenu' : 'contentWithoutMenu'
      }
    >
      {hasSideMenu && (
        <ul className="menuContainer">
          {isStaff && renderSideMenu(StaffMenu)}
          {isBlankContract && renderSideMenu(BlankContractMenu)}
          {!isStaff && !isBlankContract && renderSideMenu(Menu)}
        </ul>
      )}
      {hasSideBar && sideBarContent && (
        <div className="menuContainer">{sideBarContent()}</div>
      )}
      <div className="contentContainer">{children}</div>
    </div>
  )
}

export default ContentLayout
