import { FormValue, FormValueType } from "types/formConfig"

export enum ActionType {
    UPDATE = 'UPDATE',
    INIT = 'INIT'
}
type Action = {
    type: ActionType
    key?: string
    payload: FormValueType[keyof FormValueType] | FormValue
}

const blankContractReducer = (state: FormValue, action: Action): FormValue => {
    const { type, payload, key } = action
    switch (type) {
        case ActionType.UPDATE: {
            if (key){
                return { ...state, [key]: payload } as FormValue
            }
            else {
                return state
            }
        }

        case ActionType.INIT: {
            return { ...state, ...payload as FormValue }
        }

        default:
            return state
    }
}

export default blankContractReducer;
