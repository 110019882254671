import { Outlet } from 'react-router-dom'
import { Breakpoints, useWindowSize } from '@urbanx/agx-ui-components'
import SideBar from 'components/sidebar/Sidebar'
import { useState } from 'react'
import MobileHeader from 'components/mobileHeader/MobileHeader'

const MainLayout = () => {
  const screenSize = useWindowSize()
  const isMobile = screenSize === Breakpoints.Mobile
  const isTabletPortrait = screenSize === Breakpoints.Tablet_Portrait
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true)
  return (
    <div className="container">
      <SideBar
        isMenuCollapsed={isMenuCollapsed}
        setIsMenuCollapsed={setIsMenuCollapsed}
      />
      {isTabletPortrait && (
        <div
          className="backdrop"
          onClick={() => setIsMenuCollapsed(true)}
        ></div>
      )}
      <div className="main-content">
        {(isMobile || isTabletPortrait) && (
          <MobileHeader
            isMenuCollapsed={isMenuCollapsed}
            setIsMenuCollapsed={setIsMenuCollapsed}
          />
        )}
        <Outlet />
      </div>
    </div>
  )
}

export default MainLayout
